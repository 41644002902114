"use client";
import { ComponentName, ContentComponent, ContentType } from "@/src/types";
import dynamic from "next/dynamic";
import { type ComponentType, type FC } from "react";
import { type IBook } from "@/src/lib";

const Iframe = dynamic(() => import("./Iframe").then((m) => m.Iframe));
const ImageText = dynamic(() => import("./ImageText").then((m) => m.ImageText));
const Media = dynamic(() => import("./Media").then((m) => m.Media));
const RichText = dynamic(() => import("./RichText").then((m) => m.RichText));
const Slider = dynamic(() => import("./Slider").then((m) => m.Slider));

const StickyCallToAction = dynamic(() =>
  import("../common").then((m) => m.StickyCallToAction)
);

type Props = {
  content: ContentType[] | undefined;
  book?: IBook;
  lng: string;
};

const componentMap = {
  "shared.image-text": ImageText,
  "shared.iframe": Iframe,
  "shared.media": Media,
  "shared.rich-text": RichText,
  "shared.slider": Slider,
} as Record<ComponentName, ComponentType<ContentComponent>>;

export const Content: FC<Props> = ({ content, book, lng }) => {
  return (
    <div className="relative">
      {content?.map((component) => {
        const Component = componentMap[component.__component.name];

        return Component ? (
          <Component
            key={component.id + component.__component.name}
            {...component.__component}
          />
        ) : null;
      })}
      {content?.length ? <StickyCallToAction lng={lng} book={book} /> : null}
    </div>
  );
};
