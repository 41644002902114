"use client";
import { ISearch } from "@/src/lib/navbar/search/types";
import { type FC, useState } from "react";
import Link from "next/link";
import { CmsQuery } from "@/src/utils/cms/query";
import { useHashtags } from "@/src/lib/hashtag/queries";
import { PageSearch } from "../common";

type Props = {
  searchWords: ISearch;
  pageTitle: string;
  lng: string;
};

export const SearchSection: FC<Props> = ({ searchWords, lng }) => {
  const [searchTerm, setSearchTerm] = useState("");

  let filter: CmsQuery = {
    filters: { name: { $contains: searchTerm } },
    locale: lng,
  };

  const { data, isFetching } = useHashtags({
    filter,
  });

  const onSearch = (s: string) => {
    setSearchTerm(s);
  };

  return (
    <>
      <h2 className="text-lg font-semibold text-primary">Hashtag</h2>
      <PageSearch words={searchWords} onChange={onSearch} />
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
        {isFetching && (
          <>
            {Array.from({ length: 80 }).map((i, index) => (
              <div
                key={index}
                className="animate-pulse bg-slate-200 rounded-full w-12 h-4"
              ></div>
            ))}
          </>
        )}
        {data?.hashtags?.map((hash) => (
          <Link href={`/${lng}/hashtag/${hash.hashtagSlug}`} key={hash.id}>
            #{hash.name}
          </Link>
        ))}
      </div>
    </>
  );
};
