"use client";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Controller, Navigation } from "swiper/modules";
import { type FC } from "react";
import Image from "next/image";
import { SliderType } from "@/src/types";
import { SliderArrow } from "../common/SliderArrow";

type Props = SliderType & {};

export const Slider: FC<Props> = ({ sliderItems }) => {
  return (
    <div className="py-6">
      <Swiper
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        modules={[Controller, Navigation]}
        allowTouchMove={false}
        className="px-20"
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {sliderItems.map(({ alternativeText, url }) => {
          return (
            <SwiperSlide key={url}>
              <div className="relative flex mx-auto items-center justify-center aspect-[14/10] px-20">
                <Image
                  alt={alternativeText}
                  src={url}
                  fill
                  sizes="(max-width: 768px) 100vw, 50vw"
                  className="object-contain"
                />
              </div>
            </SwiperSlide>
          );
        })}
        {sliderItems.length > 1 && (
          <div className="absolute inset-0 z-10 flex items-stretch justify-between">
            <div className="flex flex-col justify-center items-end">
              <button className="swiper-button-prev">
                <SliderArrow order="left" />
              </button>
            </div>
            <div className="flex flex-col justify-center items-end">
              <button className="swiper-button-next group flex justify-center items-center">
                <SliderArrow />
              </button>
            </div>
          </div>
        )}
      </Swiper>
    </div>
  );
};
